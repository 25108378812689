// Global function object
if (typeof madeBase === 'undefined') var madeBase = {};

function goBack() {
    history.back();
}

function pauseVideo() {

    var video = $('#bg-video')[0];

    video.onended = function () {
        $('#pause span').first().removeClass('fa-pause').addClass('fa-play');
    };

    if (video.currentTime == video.duration) {
        video.play();
        $('#pause span').first().removeClass('fa-play').addClass('fa-pause');
    } else {
        video.currentTime = video.duration;
    }


    // var video = document.getElementById('background-video');
    // var imgurl = "url('/wp-content/themes/microbesnorwich/img/background-placeholder@3x.png')";
    //
    // if (!(video.style.backgroundImage = imgurl)) {
    //     if (video.paused) {
    //         video.play();
    //     } else {
    //         video.pause();
    //
    //     }
    // }
    //
    // if (video.ended) {
    //     video.style.background = "no-repeat url('/wp-content/themes/microbesnorwich/img/background-placeholder@3x.png')";
    // }
};

(function ($) {

    madeBase.contentFeedSlider = function () {
        $('.content-feed-slideshow').bxSlider({
            'controls': false
        });
    };


    madeBase.scrollTo = function () {
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('[href="#/"]')
            .not('[href*="accordion"]')      // exclude grouped content
            .not('[href*="tabs"]')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, function () {

                        });
                    }
                }
            });

    };

    madeBase.responsiveVideos = function () {
        $('iframe[src*="youtube"]').parent().fitVids();
        $('iframe[src*="vimeo"]').parent().fitVids();
    };

    // madeBase.initMix = function (load, container) {
    //     if (typeof load === 'undefined') {
    //         load = {};
    //     }
    //     if (typeof container === 'undefined') {
    //         container = {};
    //     }
    //     mix.init(load, container);
    //
    //     mix.togglePaginationDisplay();
    //
    // };

    madeBase.contentBlockSlideshow = function () {
        $('.content-block-slideshow').bxSlider({
            mode: 'fade',
            captions: true,
            adaptiveHeight: true,
            controls: $('.content-block-slideshow li').length,
        });
    };

    madeBase.animateExpandCollapse = function () {
        $('.expand-collapse-trigger').click(function () {
            $(this).find('i').toggleClass('u-rotate--180');
        });
    };
    madeBase.menuInit = function () {
        var body = document.querySelector('html');
        var navToggle = document.getElementById('navToggle');
        var menuLinks = document.querySelector('.navbar-collapse');
        if (navToggle && menuLinks) {
            navToggle.addEventListener('click', function () {
                this.classList.toggle('active');
                menuLinks.classList.toggle('show');
                body.classList.toggle('menu-open')
            })
        }
    };


    $(document).ready(function () {
        madeBase.responsiveVideos();
        madeBase.contentFeedSlider();
        madeBase.contentBlockSlideshow();
        madeBase.animateExpandCollapse();
        madeBase.scrollTo();
        madeBase.menuInit();
        if (madeHelpers.detectIE() == "11" || madeHelpers.detectIE() == "10") {
            document.querySelector('html').classList.add('ie');
            document.querySelector('.ie-container').classList.add('container');
        }


        if($('.open-image').length > 0){

            $('.open-image').click(function(){
                $('.image-lightbox').toggleClass('show');
            })
            $('.image-lightbox').click(function(e){
                console.log(e.currentTarget, this, $(this));
                if(e.currentTarget != this){
                    return;
                }
                $(this).toggleClass('show');
            })
        }

        // if ($('body.page-news').length) {
        //     var load = {
        //         sort: 'filter--featured:dsc filter--date:dsc',
        //     };
        //     madeBase.initMix(load);
        // }
        //
        // if ($('body.page-events').length) {
        //     var load = {
        //         filter: '[data-filter--era=future]',
        //         sort: 'filter--featured:dsc filter--date:asc',
        //     };
        //     // madeBase.initMix(load);
        // }


    });
})(jQuery);